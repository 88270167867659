import React, { useState, useRef, useEffect } from "react";
import { debounce } from "lodash";
import "./ExploreOverview.scss";
import {
  Row,
  Col,
  Table,
  Radio,
  Progress,
  Spin,
  Tooltip,
  InputNumber,
} from "antd";
import { LoadingOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import Highcharts, { chart } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMaps from "highcharts/highmaps";
import worldMap from "@highcharts/map-collection/custom/world.geo.json";
import fullscreen from "highcharts/modules/full-screen";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
import { Empty } from "antd";

// Initialize the noData module
import {
  getAgeChart,
  getGeographyChart,
  getDemographicsChart,
  getQuestionsRespondentData,
  getPatientFunnelChart,
  getGenderChart,
  getTaSubIndication,
} from "../../services/ExploreAPI";
import {
  AGE_GENDER_CHART_OPTIONS,
  DEMOGRAPHICS_EX_US_CHART_OPTIONS,
  GEO_BAR_CHART_OPTIONS,
  GENDER_CHART_OPTIONS,
} from "../../shared/Charts/ExploreCharts";
import ResetZoomIcon from "../../assets/icons/reset-zoom.svg";
import ExpandIcon from "../../assets/icons/expand.svg";
import InfoIcon from "../../assets/icons/info.svg";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
fullscreen(Highcharts);

const ExploreOverview = (props) => {
  HighchartsNoData(Highcharts);

  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const filterAppliedData = useSelector(
    (state) => state.PEBCommon?.exploreFilterApplyData
  );
  const searchString = useSelector(
    (state) => state.PEBCommon?.searchTextExplore
  );
  const analysisData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );
  const [loading, setLoading] = useState(false);
  const [geoDataList, setGeoDataList] = useState();
  const [chartOptions, setChartOptions] = useState(null);
  const [geoListOptions, setGeoListOptions] = useState(null);
  const [therapyChartOption, setTherapyChartOption] = useState(null);
  const [indicationChartOption, setIndicationChartOption] = useState(null);
  const [subIndicationChartOption, setSubIndicationChartOption] =
    useState(null);
  const [indicationGroupChartOption, setIndicationGroupChartOption] =
    useState(null);
  const [subIndicationGroupChartOption, setSubIndicationGroupChartOption] =
    useState(null);
  const [demographicExUsChartOptions, setDemographicExUsChartOptions] =
    useState(null);
  const [demographicsChartOption, setDemographicsChartOption] = useState();
  const [ageChartOption, setAgeChartOption] = useState();
  const [genderChartOption, setGenderChartOption] = useState();
  const [categoryQues, setCategoryQues] = useState();
  const [categoryQuesMax, getCategoryQuesMax] = useState();
  const [categoryQuesMaxData, setCategoryQuesMaxData] = useState();
  const [patientFunnelList, setPatientFunnelList] = useState();
  const [taIndicationList, setTaIndicationList] = useState();
  const [patientFunnelMultipleList, setPatientFunnelMultipleList] = useState(
    []
  );
  const [empty, setEmpty] = useState(false);
  const [emptyForRespondents, setEmptyForRespondents] = useState(false);
  const [maxCountFunnel, setMaxCountFunnel] = useState();
  const [maxCountTA, setMaxCountTA] = useState();
  let tickLength = 0;
  let ticksForGroupNameGrouping = {};
  let plotLines = [];
  let plotBands = [];
  let ticksForAgeGroupNameGrouping = {};
  let agePlotLines = [];
  let agePlotBands = [];
  let ageTickLength = 0;
  const [interval, setInterval] = useState(10);

  const [newAgeChartFlag, setNewAgeChartFlag] = useState();
  const [newAgeChartOption, setNewAgeChartOption] = useState();
  const [showAgeInterval, SetShowInterval] = useState();

  const [filterCategoryData, setFilterCategoryData] = useState([
    {
      key: "1",
      categoryKey: "patient-funnel",
      categoryLabel: "Patient Funnel",
      span: 12,
      isVisible: false,
      type: "table-funnel",
      selectedValue: [],
    },
    {
      key: "2",
      categoryKey: "geography",
      categoryLabel: "By Geography",
      span: 12,
      isVisible: true,
      type: "geo",
      selectedValue: [],
      radioButtons: [
        { label: "Map", id: "map", disabled: false },
        { label: "List", id: "list", disabled: false },
      ],
      radioButtonSelectedValue: "map",
    },
    // {
    //   key: "3",
    //   categoryKey: "ta",
    //   categoryLabel: "By Therapy Area",
    //   span: 12,
    //   isVisible: true,
    //   type: "bar-ta",
    //   selectedValue: [],
    // },
    {
      key: "14",
      categoryKey: "ta-ind",
      categoryLabel: "By Therapy Area",
      span: 12,
      isVisible: true,
      type: "ta-ind",
      selectedValue: [],
    },
    {
      key: "4",
      categoryKey: "indication",
      categoryLabel: "By Indication",
      span: 12,
      isVisible: false,
      type: "bar-indication",
      group: false,
      selectedValue: [],
    },
    {
      key: "5",
      categoryKey: "sub-indication",
      categoryLabel: "By Sub-Indication",
      span: 12,
      isVisible: false,
      type: "bar-sub-indication",
      group: false,
      selectedValue: [],
    },
    {
      key: "6",
      categoryKey: "age",
      categoryLabel: "By Age",
      span: 12,
      isVisible: true,
      type: "bar-age",
      selectedValue: [],
      inputField: true,
    },
    {
      key: "7",
      categoryKey: "sex_at_birth",
      categoryLabel: "By Sex at Birth",
      span: 12,
      isVisible: true,
      type: "bar-sex-at-birth",
      selectedValue: [],
    },
    {
      key: "8",
      categoryKey: "demographic",
      categoryLabel: "By Demographic",
      span: 12,
      isVisible: true,
      type: "bar-demographics",
      selectedValue: [],
      radioButtons: [
        { label: "US", id: "grouped", disabled: false },
        {
          label: "Ex-US",
          id: "ex-us-list",
          disabled: false,
        },
      ],
      radioButtonSelectedValue: "grouped",
    },

    {
      key: "9",
      categoryKey: "category",
      categoryLabel: "Categories Questions",
      span: 12,
      isVisible: true,
      type: "table",
      selectedValue: [],
    },

    {
      key: "10",
      categoryKey: "table-funnel-max",
      categoryLabel: "Patient Funnel",
      span: 24,
      isVisible: false,
      type: "table-funnel-max",
      selectedValue: [],
    },
    {
      key: "11",
      categoryKey: "table-max",
      categoryLabel: "Categories Questions",
      span: 24,
      isVisible: false,
      type: "table-max",
      selectedValue: [],
    },
    {
      key: "12",
      categoryKey: "table-funnel-max-two-criteria",
      categoryLabel: "Patient Funnel",
      span: 12,
      isVisible: false,
      type: "table-funnel-max-two-criteria",
      selectedValue: [],
    },
    {
      key: "13",
      categoryKey: "table-max-two-criteria",
      categoryLabel: "Categories Questions",
      span: 12,
      isVisible: false,
      type: "table-max-two-criteria",
      selectedValue: [],
    },
  ]);

  //columns for 5 criteria Selected
  let [columnsMax, setColumnsMax] = useState([]);

  let [payload, setPayload] = useState({
    user_id: userInfo.user_id,
    selected_question_ids: [],
    therapeutic_area: [],
    indication: [],
    geography: [],
    race: [],
    ethnicity: [],
    origin_country: [],
    age: [],
    gender: [],
    sub_indication: [],
    search_string: "",
    primary_indication:[],
    other_indication:[],
    year_range : [],
    stage : [],
  });

  const columns = [
    {
      title: "Category",
      dataIndex: "category_name",
      key: "category_name",
    },
    {
      title: "Percentage",
      dataIndex: "percentage",
      key: "percentage",
      render: (text) => <strong>{text}%</strong>,
    },
    {
      title: "Questions",
      dataIndex: "display_string",
      key: "display_string",
      render: (text) => <>{text} Questions</>,
    },
  ];
  const columnsTA = [
    {
      title: "Summary",
      dataIndex: "filter_attribute",
      key: "filter_attribute",
      width: "35%",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Respondents",
      dataIndex: "respondents_count",
      key: "respondents_count",
      render: (text, row) => (
        <Row>
          <Col style={{ width: 50, fontWeight: "bold" }}>{text}</Col>
          <Col>
            <Progress
              percent={(text / maxCountTA) * 100}
              size={[300, 20]}
              strokeLinecap={"square"}
              lineBorderRadius={50}
              showInfo={false}
              strokeColor={row.color}
            />
          </Col>
        </Row>
      ),
    },
  ];
  //Patient Funnel Percentage bars
  const columnsFunnel = [
    {
      title: "Summary",
      dataIndex: "filter_attributes",
      key: "filter_attributes",
      width: "35%",
    },
    {
      title: "Respondents",
      dataIndex: "respondent_count",
      key: "respondent_count",
      render: (text) => (
        <Row>
          <Col style={{ width: 50, fontWeight: "bold" }}>{text}</Col>
          <Col>
            <Progress
              percent={(text / maxCountFunnel) * 100}
              size={[300, 20]}
              strokeLinecap={"square"}
              lineBorderRadius={50}
              showInfo={false}
            />
          </Col>
        </Row>
      ),
    },
  ];

  const barChartRef = useRef(null);
  const ageChartRef = useRef(null);
  const newAgeChartRef = useRef(null);
  const genderChartRef = useRef(null);
  const demographicsbarchartRef = useRef(null);

  let fullScreenGroup;

  // demographics chart options
  const options2 = {
    chart: {
      type: "bar",
      events: {
        load() {
          const chart = this;
          const x = chart.plotSizeX + 500;
          const y = chart.plotTop;
          fullScreenGroup?.destroy();

          fullScreenGroup = chart.renderer
            .image(ExpandIcon, x, y, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        fullscreenOpen() {
          const chart = this;
          const x = chart.plotSizeX + 1500;
          const y = chart.plotTop;
          fullScreenGroup?.destroy();

          fullScreenGroup = chart.renderer
            .image(ExpandIcon, x, y, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        fullscreenClose() {
          const chart = this;
          const x = chart.plotSizeX + 500;
          const y = chart.plotTop;
          fullScreenGroup?.destroy();

          fullScreenGroup = chart.renderer
            .image(ExpandIcon, x, y, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        render() {
          const chart = this;
          const x = chart.plotSizeX + 500;
          const y = chart.plotTop;
          fullScreenGroup?.destroy();

          fullScreenGroup = chart.renderer
            .image(ExpandIcon, x, y, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
      },
      scrollablePlotArea: {
        minHeight: 400,
        opacity: 1,
      },
    },
    title: {
      text: "By Demographics",
      align: "left",
      style: {
        fontSize: "16px",
      },
    },
    subtitle: {
      text: "Find additional demographic details in the SDOH & Lifestyle category on the View Insights tab.",
      align: "left",
      style: {
        fontSize: "14px",
      },
    },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        pointWidth: 30,
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: [
      {
        lineWidth: 0.5,
        tickWidth: 0,
        categories: "",
        plotLines,
        plotBands,
      },
      {
        labels: {
          formatter: "",
          useHTML: true,
          style: {
            fontSize: "14px",
            fontFamily: "Open Sans, sans-serif",
            width: "110px",
            margin: 10,
            textOverflow:"none" 
          },
        },
        linkedTo: 0,
        lineWidth: 0.2,
        tickWidth: 0,
        margin: 8,
      },
      {
        lineWidth: 1,
        offset: 0,
        plotLines,
        plotBands,
      },
    ],
    yAxis: [
      {
        title: {
          text: "Respondents",
          style: {
            color: "#666666",
            fontSize: "16px ",
          },
        },
        lineWidth: 1,
        tickAmount: 8,
        labels: {
          enabled: true,
           style: {
            fontSize: "12px",
            // textOverflow:"none"
          },
        },
        max: 500,
      },
    ],
    colors: ["#6328B8"],
    series: [
      {
        name: "Respondent",
        data: [],
      },
    ],
  };

  const ageOptions2 = {
    chart: {
      type: "bar",
      events: {
        load() {
          const chart = this;
          const x = chart.plotSizeX + 500;
          const y = chart.plotTop;
          fullScreenGroup?.destroy();

          fullScreenGroup = chart.renderer
            .image(ExpandIcon, x, y, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        fullscreenOpen() {
          const chart = this;
          const x = chart.plotSizeX + 1500;
          const y = chart.plotTop;
          fullScreenGroup?.destroy();

          fullScreenGroup = chart.renderer
            .image(ExpandIcon, x, y, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        fullscreenClose() {
          const chart = this;
          const x = chart.plotSizeX + 500;
          const y = chart.plotTop;
          fullScreenGroup?.destroy();

          fullScreenGroup = chart.renderer
            .image(ExpandIcon, x, y, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        render() {
          const chart = this;
          const x = chart.plotSizeX + 500;
          const y = chart.plotTop;
          fullScreenGroup?.destroy();

          fullScreenGroup = chart.renderer
            .image(ExpandIcon, x, y, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
      },
      scrollablePlotArea: {
        minHeight: 400,
        opacity: 1,
      },
    },
    title: {
      text: "By Age",
      align: "left",
      style: {
        fontSize: "16px",
      },
    },
    // subtitle: {
    //   text: "Find additional demographic details in the SDOH & Lifestyle category on the View Insights tab.",
    //   align: "left",
    //   style: {
    //     fontSize: "14px",
    //   },
    // },
    legend: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        pointWidth: 30,
        dataLabels: {
          enabled: true,
        },
      },
    },
    xAxis: [
      {
        lineWidth: 0.5,
        tickWidth: 0,
        categories: "",
        agePlotLines,
        agePlotBands,
      },
      {
        labels: {
          formatter: "",
          useHTML: true,
          style: {
            fontSize: "14px",
            fontFamily: "Open Sans, sans-serif",
            width: "110px",
            margin: 10,
          },
        },
        linkedTo: 0,
        lineWidth: 0.2,
        tickWidth: 0,
        margin: 8,
      },
      {
        lineWidth: 1,
        offset: 0,
        agePlotLines,
        agePlotBands,
      },
    ],
    yAxis: [
      {
        title: {
          text: "Respondents",
          style: {
            color: "#666666",
            fontSize: "16px ",
          },
        },
        lineWidth: 1,
        tickAmount: 8,
        labels: {
          enabled: true,
        },
        max: 500,
      },
    ],
    colors: ["#6328B8"],
    series: [
      {
        name: "Respondent",
        data: [],
      },
    ],
  };

  let fullScreenGeo;
  let resetZoomGeo;

  let data = {
    credits: { enabled: false },
    chart: {
      map: worldMap, //topology,
      events: {
        load() {
          fullscreen(HighchartsMaps);
          const chart = this;
          const x = chart.plotSizeX - 17;
          const y = chart.plotTop + 75;
          const y1 = chart.plotTop + 40;
          resetZoomGeo?.destroy();
          fullScreenGeo?.destroy();
          resetZoomGeo = chart.renderer
            .image(ResetZoomIcon, x, y, 29, 29)
            .on("click", () => {
              chart.zoomOut();
            })
            .add()
            .toFront();

          fullScreenGeo = chart.renderer
            .image(ExpandIcon, x, y1, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        fullscreenOpen() {
          fullscreen(HighchartsMaps);
          const chart = this;
          const x = chart.plotSizeX - 17;
          const y = chart.plotTop + 75;
          const y1 = chart.plotTop + 40;
          resetZoomGeo?.destroy();
          fullScreenGeo?.destroy();
          resetZoomGeo = chart.renderer
            .image(ResetZoomIcon, x, y, 29, 29)
            .on("click", () => {
              chart.zoomOut();
            })
            .add()
            .toFront();

          fullScreenGeo = chart.renderer
            .image(ExpandIcon, x, y1, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        fullscreenClose() {
          fullscreen(HighchartsMaps);
          const chart = this;
          const x = chart.plotSizeX - 17;
          const y = chart.plotTop + 75;
          const y1 = chart.plotTop + 40;
          resetZoomGeo?.destroy();
          fullScreenGeo?.destroy();
          resetZoomGeo = chart.renderer
            .image(ResetZoomIcon, x, y, 29, 29)
            .on("click", () => {
              chart.zoomOut();
            })
            .add()
            .toFront();

          fullScreenGeo = chart.renderer
            .image(ExpandIcon, x, y1, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
        render() {
          fullscreen(HighchartsMaps);
          const chart = this;
          const x = chart.plotSizeX - 17;
          const y = chart.plotTop + 75;
          const y1 = chart.plotTop + 40;
          resetZoomGeo?.destroy();
          fullScreenGeo?.destroy();
          resetZoomGeo = chart.renderer
            .image(ResetZoomIcon, x, y, 29, 29)
            .on("click", () => {
              chart.zoomOut();
            })
            .add()
            .toFront();

          fullScreenGeo = chart.renderer
            .image(ExpandIcon, x, y1, 29, 29)
            .on("click", () => {
              chart?.fullscreen?.toggle();
            })
            .add()
            .toFront();
        },
      },
    },
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: ["viewFullscreen"],
        },
      },
    },
    title: {
      text: "By Geography",
      align: "left",
      style: {
        fontSize: "16px",
      },
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        verticalAlign: "top",
        align: "right",
        alignTo: "spacingBox",
      },
    },
    legend: {
      style: {
        display: "flex",
        flexDirection: "row",
      },
      title: {
        text: "Respondents: ",
        align: "left",
        style: {
          color:
            // theme
            (Highcharts.defaultOptions &&
              Highcharts.defaultOptions.legend &&
              Highcharts.defaultOptions.legend.title &&
              Highcharts.defaultOptions.legend.title.style &&
              Highcharts.defaultOptions.legend.title.style.color) ||
            "black",
          fontSize: "14px",
          fontWeight: "bold",
        },
      },
      width: "100%",
      height: 10,
      reversed: true,
      align: "left",
      alignColumns: false,
      verticalAlign: "bottom",
      floating: false,
      layout: "horizontal",
      valueDecimals: 0,
      backgroundColor:
        // theme
        (Highcharts.defaultOptions &&
          Highcharts.defaultOptions.legend &&
          Highcharts.defaultOptions.legend.backgroundColor) ||
        "rgba(255, 255, 255, 0.85)",
      symbolRadius: 50,
      symbolHeight: 14,
    },
    colorAxis: {
      dataClasses: [
        {
          from: -1,
          to: 1,
          color: "rgba(227, 227, 227, 1)",
          name: "No Respondents",
        },
        {
          from: 1,
          to: 100,
          color: "rgba(196, 227, 255, 1)",
          name: "Less than 100",
        },
        {
          from: 100,
          to: 1000,
          color: "rgba(47, 155, 255, 1)",
          name: "100 to 1,000",
        },
        {
          from: 1000,
          color: "rgba(5, 36, 164, 1)",
          name: "Over 1,000",
        },
      ],
    },
    series: [
      {
        name: "Respondents",
        states: {
          hover: {
            color: "rgba(98, 210, 209, 1)",
          },
        },
        dataLabels: {
          enabled: false,
          format: "{point.name}",
        },
        allAreas: false,
        data: geoDataList,
      },
    ],
  };

  const chartRef = React.useRef(null);

  const onRadioChange = (e, category) => {
    if (e.target.value === "grouped") {
      getDemographicsChartData("us");
    } else if (e.target.value === "ex-us-list") {
      getDemographicsChartData("ex-us");
    }
    setFilterCategoryData((prev) => {
      const newState = [...prev].map((ctg) => {
        if (ctg.key === category.key)
          ctg.radioButtonSelectedValue = e.target.value;
        return ctg;
      });
      return newState;
    });
  };

  //Age Chart Plotting
  const agePLotting = (ageDataList, ageNames, ageCounts) => {
    agePlotLines =[];
    agePlotBands=[];
    ageTickLength=0; 
    ticksForAgeGroupNameGrouping = {};
    ageDataList.forEach((category, index) => {
      // allCategoriesGrouping = [...allCategoriesGrouping, ...category.subCategory];
      ticksForAgeGroupNameGrouping[Math.trunc(ageTickLength)] =
        category.group_name;
      if (index < ageDataList.length - 1)
        agePlotLines.push({
          color: "#DEDCDE",
          width: 1,
          value: ageTickLength + category.data.length - 0.5,
        });
      if (index < ageDataList.length - 1)
        agePlotBands.push({
          color: "transparent",
          from: ageTickLength + category.data.length - 0.5,
          to: ageTickLength + category.data.length - 0.5,
          borderWidth: 1,
          borderColor: "#DEDCDE",
          label: {
            text: "",
            rotation: -90,
            textAlign: "center",
          },
        });
      ageTickLength += category.data.length;
    });
    // setdemographicsPlotting(ticksForGroupNameGrouping, plotLines, plotBands);
    let ageOptions = JSON.parse(JSON.stringify(ageOptions2));
    ageOptions.chart.scrollablePlotArea.minHeight =
      ageNames.length > 0 ? ageNames.length * 60 : 400;
    ageOptions.xAxis[0].categories = ageNames;
    ageOptions.xAxis[0].plotLines = agePlotLines;
    ageOptions.xAxis[2].plotLines = agePlotLines;
    ageOptions.yAxis[0].max =
      Math.max(...ageCounts) + Math.max(...ageCounts) / 5;
    ageOptions.series[0].data = ageCounts;

    ageOptions.xAxis[1].labels.formatter = function () {
      return ticksForAgeGroupNameGrouping[this.value]
        ? "<p style='font-weight:700'>" +
            ticksForAgeGroupNameGrouping[this.value] +
            "</p>"
        : "";
    };
    //setDemographicsChartOption(demoOptions);
    setNewAgeChartOption(ageOptions);
  };

  //Demographics Chart Plotting
  const demographicsPLotting = (
    dataList,
    demographicNames,
    demographicsCounts
  ) => {
    dataList.forEach((category, index) => {
      // allCategoriesGrouping = [...allCategoriesGrouping, ...category.subCategory];
      ticksForGroupNameGrouping[Math.trunc(tickLength)] = category.group_name;
      if (index < dataList.length - 1)
        plotLines.push({
          color: "#DEDCDE",
          width: 1,
          value: tickLength + category.data.length - 0.5,
        });
      if (index < dataList.length - 1)
        plotBands.push({
          color: "transparent",
          from: tickLength + category.data.length - 0.5,
          to: tickLength + category.data.length - 0.5,
          borderWidth: 1,
          borderColor: "#DEDCDE",
          label: {
            text: "",
            rotation: -90,
            textAlign: "center",
          },
        });
      tickLength += category.data.length;
    });

    // setdemographicsPlotting(ticksForGroupNameGrouping, plotLines, plotBands);
    let demoOptions = JSON.parse(JSON.stringify(options2));
    demoOptions.chart.scrollablePlotArea.minHeight =
      demographicNames.length > 0 ? demographicNames.length * 60 : 400;
    demoOptions.xAxis[0].categories = demographicNames;
    demoOptions.xAxis[0].plotLines = plotLines;
    demoOptions.xAxis[2].plotLines = plotLines;
    demoOptions.yAxis[0].max =
      Math.max(...demographicsCounts) + Math.max(...demographicsCounts) / 5;
    demoOptions.series[0].data = demographicsCounts;

    demoOptions.xAxis[1].labels.formatter = function () {
      return ticksForGroupNameGrouping[this.value]
        ? "<p style='font-weight:700'>" +
            ticksForGroupNameGrouping[this.value] +
            "</p>"
        : "";
    };
    setDemographicsChartOption(demoOptions);
  };

  //get geography API data
  const getGeographyChartData = async (payload) => {
    let response = await getGeographyChart(payload);
    // data = topology.objects.default.geometries.map((g, i) => i);

    setGeoDataList(response.data.list_data);
    data.series[0].data = response.data.list_data;
    // List View
    let geoListOptions = JSON.parse(JSON.stringify(GEO_BAR_CHART_OPTIONS));
    geoListOptions.title.text = "By Geography";
    const listData = [];
    response.data.list_data.forEach((data) => {
      worldMap.features.forEach((country) => {
        if (data[0].toLowerCase() === country.id.toLowerCase()) {
          listData.push([country.properties.name, data[1]]);
        }
      });
    });
    geoListOptions.series[0].data = listData;
    geoListOptions.chart.scrollablePlotArea.minHeight =
      response.data.list_data?.length > 0
        ? response.data.list_data?.length * 60
        : 400;
    geoListOptions.plotOptions.bar.pointWidth = 30;
    setGeoListOptions(geoListOptions);
    setChartOptions(data);
  };
  HighchartsMaps.setOptions({
    lang: {
      noData: "No data available for the selected filters",
      thousandsSep: ",",
      numericSymbols: null,
    },
  });
  Highcharts.setOptions({
    lang: {
      noData: "No data available for the selected filters",
      thousandsSep: ",",
      numericSymbols: null,
    },
  });


  //get Age Chart API data
  const getAgeChartData = async (payload, interval) => {
    let newPayload = { ...payload, age_interval: +interval };
    let response = await getAgeChart(newPayload);
    setNewAgeChartFlag(response.new_age_chart)
    if (!response.new_age_chart) {
      SetShowInterval(false);
      let chartOption = JSON.parse(JSON.stringify(AGE_GENDER_CHART_OPTIONS));

      chartOption.title.text = "By Age";
      chartOption.series[0].data = response.data.list_data;
      chartOption.chart.scrollablePlotArea.minHeight =
        response.data.list_data?.length > 0
          ? response.data.list_data?.length * 60
          : 400;
      chartOption.plotOptions.bar.pointWidth = 30;
      // chartOption.chart.scrollablePlotArea.minHeight =
      //   response.data.list_data?.length > 4 ? 600 : 400;
      setAgeChartOption(chartOption);
    } else {
      SetShowInterval(true);
      const ageDataList = response.data.list_data;
      const sortedAgeDataList = [...ageDataList].sort(
        (a, b) => a.age_order - b.age_order
      );
      const flattenedAgeData = sortedAgeDataList.flatMap((item) => item.data);
      const [ageNames, ageCounts] = flattenedAgeData.reduce(
        ([names, counts], [name, count]) => {
          names.push(name);
          counts.push(count);
          return [names, counts];
        },
        [[], []]
      );
      agePLotting(sortedAgeDataList, ageNames, ageCounts);
    }
  };

  //get Gender Chart API data
  const getGenderChartData = async (payload) => {
    let response = await getGenderChart(payload);
    let chartOption = JSON.parse(JSON.stringify(GENDER_CHART_OPTIONS));
    chartOption.title.text = "By Sex at Birth";
    chartOption.series[0].data = response.data.list_data;
    chartOption.chart.scrollablePlotArea.minHeight =
      response.data.list_data?.length > 0
        ? response.data.list_data?.length * 60
        : 400;
    chartOption.plotOptions.bar.pointWidth = 30;
    // chartOption.chart.scrollablePlotArea.minHeight =
    //   response.data.list_data?.length > 4 ? 600 : 400;
    setGenderChartOption(chartOption);
  };

  //get Demographics Chart API data
  const getDemographicsChartData = async (viewType) => {
    let type = "us";
    if (viewType) {
      type = viewType;
    } else if (
      filterAppliedData[0]?.geography.includes("United States of America")
    ) {
      type = "us";
    } else if (filterAppliedData[0]?.geography.length) {
      type = "ex-us";
    }
    let newState = [...filterCategoryData];

    const payload = {
      user_id: userInfo.user_id,
      selected_question_ids: [],
      search_string: searchString || "",
      therapeutic_area: filterAppliedData[0]?.therapeutic_area || [],
      indication: filterAppliedData[0]?.indication || [],
      geography: filterAppliedData[0]?.geography || [],
      race: filterAppliedData[0]?.race || [],
      ethnicity: filterAppliedData[0]?.ethnicity || [],
      origin_country: filterAppliedData[0]?.origin_country || [],
      age: filterAppliedData[0]?.age || [],
      gender: filterAppliedData[0]?.gender || [],
      sub_indication: filterAppliedData[0]?.sub_indication || [],
      view_type: type,
      analysis_id: localStorage.getItem("analysisId") || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
      primary_indication:filterAppliedData[0]?.primary_indication || [],
      other_indication:filterAppliedData[0]?.other_indication || [],
      year_range : filterAppliedData[0]?.year_range || [],
      stage : filterAppliedData[0]?.stage || [],
    };
    let response = await getDemographicsChart(payload);
    const dataList = response.data.list_data;

    if (!filterAppliedData[0]?.geography.includes("United States of America")) {
      newState.forEach((ctg) => {
        if (
          ctg.categoryKey === "demographic" &&
          filterAppliedData[0]?.geography.length > 0
        ) {
          ctg.radioButtons[0].disabled = true;
          ctg.radioButtons[1].disabled = false;

          ctg.radioButtonSelectedValue = "ex-us-list";
        } else if (ctg.categoryKey === "demographic") {
          ctg.radioButtons[0].disabled = false;
          ctg.radioButtons[1].disabled = false;

          if (type == "us") ctg.radioButtonSelectedValue = "grouped";
          else ctg.radioButtonSelectedValue = "ex-us-list";
        }
      });
    } else {
      newState.forEach((ctg) => {
        if (
          ctg.categoryKey === "demographic" &&
          filterAppliedData[0]?.geography.length === 1
        ) {
          ctg.radioButtons[0].disabled = false;
          ctg.radioButtons[1].disabled = true;
          if (type == "us") ctg.radioButtonSelectedValue = "grouped";
          else ctg.radioButtonSelectedValue = "ex-us-list";
          // ctg.radioButtonSelectedValue = "grouped";
        } else if (ctg.categoryKey === "demographic") {
          ctg.radioButtons[0].disabled = false;
          ctg.radioButtons[1].disabled = false;
          if (type == "us") ctg.radioButtonSelectedValue = "grouped";
          else ctg.radioButtonSelectedValue = "ex-us-list";
        }
      });
    }

    setFilterCategoryData([...newState]);

    if (response.data.view_type === "us") {
      console.log("demographics -- ", response.data.list_data);
      const flattenedIndicationData = response.data.list_data.flatMap(
        (item) => item.data
      );
      const [demographicNames, demographicsCounts] =
        flattenedIndicationData.reduce(
          ([names, counts], [name, count]) => {
            names.push(name);
            counts.push(count);
            return [names, counts];
          },
          [[], []]
        );
      demographicsPLotting(dataList, demographicNames, demographicsCounts);
    } else {
      let chartOption = JSON.parse(
        JSON.stringify(DEMOGRAPHICS_EX_US_CHART_OPTIONS)
      );
      chartOption.title.text = "By Demographics";

      let name = [];
      let value = [];
      let dataList = response.data.list_data;
      dataList.forEach((item) => {
        name.push(item[0]);
        value.push(item[1]);
      });
      chartOption.series[0].data = value;
      chartOption.xAxis[0].categories = name;
      chartOption.xAxis[1].labels.formatter = function () {
        return this.value === 0
          ? "<p style='font-weight:700'>  Race</p>"
          : "";
      };
      chartOption.chart.scrollablePlotArea.minHeight =
        response.data.list_data?.length > 0
          ? response.data.list_data?.length * 60
          : 400;
      chartOption.plotOptions.bar.pointWidth = 30;
      setDemographicExUsChartOptions(chartOption);
    }
  };

  //to get Categories with Questions that Have 25+ Respondents API data
  const getQuestionsRespondent = async () => {
    const payload = {
      user_id: userInfo.user_id,
      comparator_flag: false,
      search_string: searchString || "",
      analysis_id: localStorage.getItem("analysisId") || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
    };
    let response = await getQuestionsRespondentData(payload);
    // const newArr = response.data.list_data.filter((i) => i.percentage > 0);
    const newArr = response.data.list_data;

    setCategoryQues(newArr);
    if (newArr.length == 0) {
      setEmptyForRespondents(true);
    } else {
      setEmptyForRespondents(false);
    }
  };

  //to get Categories with Questions that Have 25+ Respondents API data MAX
  const getQuestionsRespondentMax = async () => {
    const payload = {
      user_id: userInfo.user_id,
      comparator_flag: true,
      search_string: searchString || "",
      analysis_id: localStorage.getItem("analysisId") || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
    };
    let response = await getQuestionsRespondentData(payload);
    getCategoryQuesMax(response.data.list_data);
    let criteriaMax = response.data.list_data;
    let colObj = [];

    // setColumnsMax([...columnsMax, ...colObj]);
    criteriaMax.forEach((item, index) => {
      const obj = {
        title: item.criteria_name,
        dataIndex: `total_questions_percent${index + 1}`,
        key: `total_questions_percent${index + 1}`,
        ellipsis: true,
        render: (_, record) => (
          <Tooltip
            placement="topLeft"
            title={`${record[`question_count${index + 1}`]}/${
              record[`total_questions${index + 1}`]
            } Questions`}>
            <strong>{record[`total_questions_percent${index + 1}`]}%</strong>
          </Tooltip>
        ),
      };
      colObj.push(obj);
    });
    setColumnsMax([
      {
        title: "Category",
        dataIndex: "category_name",
        key: "category_name",
        width: "40%",
        render: (_, record) => (
          <span>
            {record.category_name}
            {/* ({record.total_questions}) */}
          </span>
        ),
      },
      ...colObj,
    ]);

    // Find the length of the longest inner array
    const maxLength = Math.max(
      ...criteriaMax.map((obj) => obj.categories_list.length)
    );
    // Create a new array of objects
    const newArray = [];
    setCategoryQuesMaxData([]);
    for (let i = 0; i < maxLength; i++) {
      const newObj = { index: i + 1 };
      criteriaMax.forEach((obj, index) => {
        if (i < obj.categories_list.length) {
          newObj["category_name"] = obj.categories_list[i].category_name;
          newObj[`total_questions${index + 1}`] =
            obj.categories_list[i].total_questions;
          newObj[`total_questions_percent${index + 1}`] =
            obj.categories_list[i].percentage;
          newObj[`question_count${index + 1}`] =
            obj.categories_list[i].questions_count_greater_than_25;
        } else {
          newObj[`total_questions_percent${index + 1}`] = null; // Handle cases where inner array is shorter
        }
      });
      newArray.push(newObj);
    }
    setCategoryQuesMaxData(newArray);
  };

  //to get Patient Funnel API data
  const getPatientFunnelAPIData = async () => {
    const payload = {
      user_id: userInfo.user_id,
      comparator_flag: false,
      analysis_id: localStorage.getItem("analysisId") || "",
      search_string: searchString || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
    };
    let response = await getPatientFunnelChart(payload);
    setMaxCountFunnel(response?.data.max_count);
    const updatedPatientFunnelList =
      response?.data?.patient_funnel_details?.map((item, index) => {
        const updatedItem = {
          ...item,
          key: index + 1,
        };

        return updatedItem;
      });
    setPatientFunnelList(updatedPatientFunnelList);

    if (
      response?.data?.patient_funnel_details?.length > 1 &&
      filterAppliedData.length === 1
    ) {
      let newState = [...filterCategoryData];
      newState.forEach((ctg) => {
        if (ctg.categoryKey === "patient-funnel") ctg.isVisible = true;
      });
      setFilterCategoryData([...newState]);
    } else {
      let newState = [...filterCategoryData];
      newState.forEach((ctg) => {
        if (ctg.categoryKey === "patient-funnel") ctg.isVisible = false;
      });
      setFilterCategoryData([...newState]);
    }
  };

  const getTaIndication = async (payload) => {
    let response = await getTaSubIndication(payload);
    setMaxCountTA(response?.data?.max_count);
    const addColorsToItems = (data, level = 0, parentKey = "") => {
      return data.map((item, index) => {
        const color =
          level === 0 ? "#0952CA" : level === 1 ? "#2F9BFF" : "#C4E3FF";
        const key = parentKey ? `${parentKey}-${index + 1}` : `${index + 1}`;
        const updatedItem = {
          ...item,
          color,
          key,
        };

        if (item.children && item.children.length > 0) {
          updatedItem.children = addColorsToItems(
            item.children,
            level + 1,
            key
          );
        }

        return updatedItem;
      });
    };

    const updatedTaIndicationList = addColorsToItems(response?.data?.list_data);

    setTaIndicationList(updatedTaIndicationList);
  };
  //to get Patient Funnel API data for multiple Criteria
  const getPatientFunnelCriteriaAPIData = async () => {
    const payload = {
      user_id: userInfo.user_id,
      comparator_flag: true,
      analysis_id: localStorage.getItem("analysisId") || "",
      search_string: searchString || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
    };
    let response = await getPatientFunnelChart(payload);
    let funnelData = response.data.patient_funnel_details;
    const maxLength = Math.max(
      ...funnelData.map((item) => item.Criteria_value.length)
    );

    funnelData = funnelData.map((item) => ({
      ...item,
      Criteria_value: Array.from(
        { length: maxLength },
        (_, index) =>
          item.Criteria_value[index] || {
            filter_attributes: "",
            respondent_count: null,
          }
      ),
    }));
    let newArr = [];
    if (!funnelData) {
      setEmpty(true);
    } else {
      setEmpty(false);
    }
    funnelData?.forEach((item) => {
      let obj = {
        headers: [
          {
            title: item.Criteria_name,
            dataIndex: "filter_attributes",
            key: "filter_attributes",

            ellipsis: {
              showTitle: false,
            },
            render: (values) => (
              <Tooltip placement="topLeft" title={values}>
                {values}
              </Tooltip>
            ),
            align: "left", // Set colSpan to 2 for the first column
          },
          {
            width: "30%",

            title: "Respondents",
            dataIndex: "respondent_count",
            key: "respondent_count",
            render: (text) => <strong>{text}</strong>,
            align: "right",
            ellipsis: true,
            colSpan: 1, // Set colSpan to 2 for the first column
          },
        ],
        value: item.Criteria_value,
      };
      newArr.push(obj);
    });

    let newState = [...filterCategoryData];
    newState.forEach((ctg) => {
      if (ctg.categoryKey === "patient-funnel") ctg.isVisible = false;
    });
    setFilterCategoryData([...newState]);

    newArr.forEach((criteria) => {
      let keyCounter = 1;

      criteria.value.forEach((valueObject) => {
        valueObject.key = keyCounter++;

        if (valueObject.children) {
          valueObject.children.forEach((child) => {
            child.key = keyCounter++;
          });
        }
      });
    });

    setPatientFunnelMultipleList(newArr);
  };

  const getOverviewData = () => {
    setLoading(true);

    if (filterAppliedData && filterAppliedData.length) {
      let temPayload = {
        user_id: userInfo.user_id,
        selected_question_ids: [],
        search_string: searchString || "",
        therapeutic_area: filterAppliedData[0].therapeutic_area || [],
        indication: filterAppliedData[0].indication || [],
        geography: filterAppliedData[0].geography || [],
        race: filterAppliedData[0].race || [],
        ethnicity: filterAppliedData[0].ethnicity || [],
        origin_country: filterAppliedData[0].origin_country || [],
        age: filterAppliedData[0].age || [],
        gender: filterAppliedData[0].gender || [],
        sub_indication: filterAppliedData[0].sub_indication || [],
        analysis_id: localStorage.getItem("analysisId") || "",
        analysis_refresh_date: analysisData?.last_updated || "",
        ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
        primary_indication:filterAppliedData[0].primary_indication || [],
        other_indication:filterAppliedData[0].other_indication || [],
        year_range : filterAppliedData[0]?.year_range || [],
        stage : filterAppliedData[0]?.stage || [],
      };
      setPayload(temPayload);
      if (filterAppliedData.length === 1) {
        getGeographyChartData(temPayload);
        getTaIndication(temPayload);
        getAgeChartData(temPayload, interval);
        getGenderChartData(temPayload);
        getDemographicsChartData();
        getQuestionsRespondent();
        getPatientFunnelAPIData();
        //Show Charts based on Business Logic
        let newState = [...filterCategoryData];

        //Don't Show Geo Charts
        newState.forEach((ctg) => {
          if (
            temPayload.geography.length === 1 &&
            ctg.categoryKey === "geography"
          )
            ctg.isVisible = false;
          if (
            temPayload.geography.length !== 1 &&
            ctg.categoryKey === "geography"
          )
            ctg.isVisible = true;

          ///
          //ta
          let taInd = newState.find((ctg) => ctg.categoryKey === "ta-ind");

          //let ta = newState.find((ctg) => ctg.categoryKey === "ta");
          taInd.isVisible = false;

          if (
            temPayload.therapeutic_area.length === 0 &&
            temPayload.indication.length === 0 &&
            temPayload.sub_indication.length === 0
          ) {
            taInd.isVisible = true;
          }
          if (
            temPayload.therapeutic_area.length === 0 &&
            temPayload.indication.length > 0 &&
            temPayload.sub_indication.length === 0
          ) {
            taInd.isVisible = true;
          }
          if (
            temPayload.therapeutic_area.length === 0 &&
            temPayload.indication.length > 0 &&
            temPayload.sub_indication.length > 0
          ) {
            taInd.isVisible = true;
          }
          //indication
          if (
            temPayload.therapeutic_area.length === 1 &&
            temPayload.indication.length === 0 &&
            temPayload.sub_indication.length === 0
          ) {
            taInd.isVisible = true;
          }

          // sub indication

          if (
            temPayload.therapeutic_area.length === 1 &&
            temPayload.indication.length === 1
          ) {
            taInd.isVisible = true;
          }
          if (
            temPayload.therapeutic_area.length === 1 &&
            temPayload.indication.length > 1 &&
            temPayload.sub_indication.length > 1
          ) {
            taInd.isVisible = true;
          }

          if (
            temPayload.therapeutic_area.length === 1 &&
            temPayload.indication.length >= 1 &&
            temPayload.sub_indication.length > 0
          ) {
            taInd.isVisible = true;
          }
          if (
            temPayload.therapeutic_area.length === 1 &&
            temPayload.indication.length >= 1 &&
            temPayload.sub_indication.length == 0
          ) {
            taInd.isVisible = true;
          }

          // ta (>1,_,_)

          if (temPayload.therapeutic_area.length > 1) {
            if (
              temPayload.indication.length === 0 &&
              temPayload.sub_indication.length === 0
            ) {
              taInd.isVisible = true;
            } else if (temPayload.indication.length === 1) {
              if (temPayload.sub_indication.length === 1) {
                taInd.isVisible = true;
              } else if (temPayload.sub_indication.length === 0) {
                taInd.isVisible = true;
              } else {
                taInd.isVisible = true;
              }
            } else if (temPayload.indication.length > 1) {
              if (temPayload.sub_indication.length <= 1) {
                taInd.isVisible = true;
              } else {
                taInd.isVisible = true;
              }
            }
          }

          //Age CHARTS
          if (temPayload.age.length === 1 && ctg.categoryKey === "age")
            ctg.isVisible = true;
          if (temPayload.age.length !== 1 && ctg.categoryKey === "age")
            ctg.isVisible = true;

          //Sex at Birth CHARTS
          if (
            temPayload.gender.length === 1 &&
            ctg.categoryKey === "sex_at_birth"
          )
            ctg.isVisible = false;
          if (
            temPayload.gender.length !== 1 &&
            ctg.categoryKey === "sex_at_birth"
          )
            ctg.isVisible = true;

          //demographics visibility
          if (ctg.categoryKey === "demographic") ctg.isVisible = true;
          if (
            temPayload.gender.length === 1 &&
            ctg.categoryKey === "demographic"
          )
            ctg.isVisible = false;

          //categories 25+ respondents visibility

          if (
            ctg.categoryKey === "table-max" ||
            ctg.categoryKey === "table-funnel-max" ||
            ctg.categoryKey === "table-funnel-max-two-criteria" ||
            ctg.categoryKey === "table-max-two-criteria"
          )
            ctg.isVisible = false;

          if (ctg.type === "table") ctg.isVisible = true;
        });
        setFilterCategoryData([...newState]);
      } else if (filterAppliedData.length === 2) {
        //Show just 2 charts when 2 criteria are selected
        let newState = [...filterCategoryData];
        newState.forEach((ctg) => {
          if (
            ctg.categoryKey === "table-max-two-criteria" ||
            ctg.categoryKey === "table-funnel-max-two-criteria"
          )
            ctg.isVisible = true;
          else ctg.isVisible = false;

          if (ctg.type === "table-funnel") {
            ctg.isVisible = false;
          }
        });
        setFilterCategoryData([...newState]);

        getQuestionsRespondentMax();

        getPatientFunnelCriteriaAPIData();
      } else if (filterAppliedData.length > 2) {
        //Show just 2 charts when more than 2 criteria selected
        let newState = [...filterCategoryData];
        newState.forEach((ctg) => {
          if (
            ctg.categoryKey === "table-max" ||
            ctg.categoryKey === "table-funnel-max"
          )
            ctg.isVisible = true;
          else ctg.isVisible = false;
        });

        setFilterCategoryData([...newState]);
        getQuestionsRespondentMax();
        getPatientFunnelCriteriaAPIData();
      }
    } else {
      payload = {
        user_id: userInfo.user_id,
        selected_question_ids: [],
        therapeutic_area: [],
        indication: [],
        geography: [],
        race: [],
        ethnicity: [],
        origin_country: [],
        age: [],
        gender: [],
        sub_indication: [],
        search_string: searchString || "",
        analysis_id: localStorage.getItem("analysisId") || "",
        analysis_refresh_date: analysisData?.last_updated || "",
        ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
        primary_indication:[],
        other_indication:[],
        year_range : [],
        stage : [],
      };
      let newState = [...filterCategoryData];
      newState.forEach((ctg) => {
        if (ctg.categoryKey === "patient-funnel") ctg.isVisible = false;
        if (ctg.categoryKey === "ta-ind") ctg.isVisible = true;
         if (ctg.categoryKey === "age") ctg.isVisible = true;
        if (ctg.categoryKey === "sex_at_birth") ctg.isVisible = true;
        // if (ctg.categoryKey === "indication") ctg.isVisible = false;
        // if (ctg.categoryKey === "sub-indication") ctg.isVisible = false;
        if (
          ctg.categoryKey === "table-max" ||
          ctg.categoryKey === "table-funnel-max"
        )
          ctg.isVisible = false;
      });

      setPayload(payload);
      setFilterCategoryData([...newState]);
      //*** Show All Charts ***//
      getGeographyChartData(payload);
      getTaIndication(payload);
      getAgeChartData(payload, interval);
      getGenderChartData(payload);
      getDemographicsChartData();
      getQuestionsRespondent();
    }
    setLoading(false);
  };
  const debouncedGetOverviewData = debounce(getOverviewData, 500);

  useEffect(() => {
    if (filterAppliedData.length > 0) {
      debouncedGetOverviewData();
    }
  }, [filterAppliedData, searchString, analysisData, interval]);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  const containerRef = useRef(null);

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 150;
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 150;
    }
  };
  const customLocale = {
    emptyText: (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<span>No data available for the selected filters!</span>}
      />
    ),
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      setInterval(e.target.value);
    }
  };

  return (
    <>
      <Spin indicator={antIcon} spinning={loading}>
        <Row gutter={[16, 16]} style={{ marginBottom: 20 }}>
          {filterCategoryData.map((category) => {
            return (
              category.isVisible && (
                <Col
                  key={category.key}
                  xl={{
                    span: ["table-funnel-max", "table-max"].includes(
                      category.categoryKey
                    )
                      ? category.span
                      : 12,
                  }}
                  lg={{
                    span: ["table-funnel-max", "table-max"].includes(
                      category.categoryKey
                    )
                      ? 24
                      : 12,
                  }}
                  className="gutter-row"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    gap: "0.5rem",
                  }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      gap: "0.5rem",
                      border: "1px solid #DEDCDE",
                      padding: 20,
                    }}>
                    {category.isVisible && category.type === "table-funnel" && (
                      <div
                        style={{
                          height: 400,
                        }}>
                        <strong
                          style={{
                            float: "left",
                            marginBottom: 20,
                            fontSize:16
                          }}>
                          Patient Funnel
                        </strong>
                        <Table
                          dataSource={patientFunnelList}
                          columns={columnsFunnel}
                          pagination={false}
                          scroll={{
                            y: 290,
                          }}
                          expandable={{
                            expandIcon: ({ expanded, onExpand, record }) => {
                              if (
                                !record?.children ||
                                record?.children?.length == 0
                              )
                                return <span></span>;
                              return expanded ? (
                                <UpOutlined
                                  style={{ marginRight: "15px" }}
                                  onClick={(e) => onExpand(record, e)}
                                />
                              ) : (
                                <DownOutlined
                                  style={{ marginRight: "15px" }}
                                  onClick={(e) => onExpand(record, e)}
                                />
                              );
                            },
                          }}
                        />
                      </div>
                    )}
                    {category.isVisible &&
                      chartOptions &&
                      Object.keys(chartOptions).length > 0 &&
                      category.type === "geo" &&
                      category.radioButtonSelectedValue === "map" && (
                        <HighchartsReact
                          highcharts={HighchartsMaps}
                          //options={chartOptions}
                          options={
                            chartOptions
                              ? {
                                  type: "map",
                                  ...chartOptions,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          constructorType={"mapChart"}
                          updateArgs={
                            searchString.length <= 3
                              ? [true, false, false]
                              : [true, true, true]
                          }
                          ref={chartRef}
                        />
                      )}
                    {category.isVisible &&
                      category.type === "geo" &&
                      geoListOptions &&
                      Object.keys(geoListOptions).length > 0 &&
                      category.radioButtonSelectedValue === "list" && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          options={
                            geoListOptions
                              ? {
                                  ...geoListOptions,
                                  type: "map",
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          //  options={geoListOptions}
                          // ref={barChartRef}
                          // updateArgs={[true, true, true]}
                        />
                      )}
                    {category.isVisible && category.type === "ta-ind" && (
                      <div
                        style={{
                          height: 400,
                        }}>
                        {" "}
                        <strong
                          style={{
                            float: "left",
                            marginBottom: 20,
                            fontSize:16,
                          }}>
                          Disease Coverage
                        </strong>
                        <Table
                          className="disease-table"
                          dataSource={taIndicationList}
                          columns={columnsTA}
                          pagination={false}
                          scroll={{
                            y: 290,
                          }}
                          expandable={{
                            expandIcon: ({ expanded, onExpand, record }) => {
                              if (
                                !record?.children ||
                                record?.children?.length == 0
                              )
                                return (
                                  <span style={{ margin: "10px" }}>{}</span>
                                );
                              return expanded ? (
                                <UpOutlined
                                  style={{ marginRight: "15px" }}
                                  onClick={(e) => onExpand(record, e)}
                                />
                              ) : (
                                <DownOutlined
                                  style={{ marginRight: "15px" }}
                                  onClick={(e) => onExpand(record, e)}
                                />
                              );
                            },
                          }}
                        />
                      </div>
                    )}
                    {/* {category.isVisible && category.type === "bar-ta" &&
                     therapyChartOption &&  Object.keys(therapyChartOption).length > 0 &&  (
                      <HighchartsReact
                        highcharts={Highcharts}
                        //options={therapyChartOption}
                        options={
                          therapyChartOption
                            ? {
                                ...therapyChartOption,
                                accessibility: {
                                  enabled: false,
                                },
                              }
                            : {}
                        }
                        ref={taBarChartRef}
                        updateArgs={[true, false, false]}
                      />
                    )}
                    {category.isVisible &&
                      category.type === "bar-ta" &&
                      therapyChartOption &&
                      Object.keys(therapyChartOption).length > 0 && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //options={therapyChartOption}
                          options={
                            therapyChartOption
                              ? {
                                  ...therapyChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          ref={taBarChartRef}
                          updateArgs={[true, false, false]}
                        />
                      )}
                    {category.isVisible &&
                      indicationChartOption &&
                      Object.keys(indicationChartOption).length > 0 &&
                      category.type === "bar-indication" &&
                      !category.group && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          // options={indicationChartOption}
                          options={
                            indicationChartOption
                              ? {
                                  ...indicationChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          updateArgs={[true, true, true]}
                        />
                      )}
                    {category.isVisible &&
                      indicationGroupChartOption &&
                      Object.keys(indicationGroupChartOption).length > 0 &&
                      category.type === "bar-indication" &&
                      category.group && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //options={indicationGroupChartOption}
                          options={
                            indicationGroupChartOption
                              ? {
                                  ...indicationGroupChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          updateArgs={[true, true, true]}
                        />
                      )}
                    {category.isVisible &&
                      subIndicationChartOption &&
                      Object.keys(subIndicationChartOption).length > 0 &&
                      category.type === "bar-sub-indication" &&
                      !category.group && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //options={subIndicationChartOption}
                          options={
                            subIndicationChartOption
                              ? {
                                  ...subIndicationChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          ref={subIndicationBarChartRef}
                          updateArgs={[true, true, true]}
                        />
                      )}
                    {category.isVisible &&
                      subIndicationGroupChartOption &&
                      Object.keys(subIndicationGroupChartOption).length > 0 &&
                      category.type === "bar-sub-indication" &&
                      category.group && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //options={subIndicationGroupChartOption}
                          options={
                            subIndicationGroupChartOption
                              ? {
                                  ...subIndicationGroupChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          // ref={subIndicationBarChartRef}
                          // updateArgs={[true, true, true]}
                        />
                      )} */}
                    {category.isVisible &&
                      category.type === "bar-age" &&
                      newAgeChartFlag &&
                      newAgeChartOption &&
                      Object.keys(newAgeChartOption).length > 0 && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //options={ageChartOption}
                          options={
                            newAgeChartOption
                              ? {
                                  ...newAgeChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          ref={newAgeChartRef}
                          updateArgs={[true, true, true]}
                        />
                      )}
                    {category.isVisible &&
                      category.type === "bar-age" &&
                      !newAgeChartFlag &&
                      ageChartOption &&
                      Object.keys(ageChartOption).length > 0 && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //options={ageChartOption}
                          options={
                            ageChartOption
                              ? {
                                  ...ageChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          ref={ageChartRef}
                          updateArgs={[true, true, true]}
                        />
                      )}
                    {category.isVisible &&
                      category.type === "bar-sex-at-birth" &&
                      genderChartOption &&
                      Object.keys(genderChartOption).length > 0 && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //options={genderChartOption}
                          options={
                            genderChartOption
                              ? {
                                  ...genderChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          ref={genderChartRef}
                          updateArgs={[true]}
                        />
                      )}
                    {category.isVisible &&
                      category.type === "bar-demographics" &&
                      demographicsChartOption &&
                      Object.keys(demographicsChartOption).length > 0 &&
                      category.radioButtonSelectedValue === "grouped" && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //  options={demographicsChartOption}
                          options={
                            demographicsChartOption
                              ? {
                                  ...demographicsChartOption,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          ref={demographicsbarchartRef}
                          updateArgs={[true, true, true]}
                        />
                      )}
                    {category.isVisible &&
                      category.type === "bar-demographics" &&
                      demographicExUsChartOptions &&
                      Object.keys(demographicExUsChartOptions).length > 0 &&
                      category.radioButtonSelectedValue === "ex-us-list" && (
                        <HighchartsReact
                          highcharts={Highcharts}
                          //options={demographicExUsChartOptions}
                          options={
                            demographicExUsChartOptions
                              ? {
                                  ...demographicExUsChartOptions,
                                  accessibility: {
                                    enabled: false,
                                  },
                                }
                              : {}
                          }
                          ref={barChartRef}
                          updateArgs={[true, true, true]}
                        />
                      )}
                    {category.isVisible && category.type === "table" && (
                      <div
                        style={{
                          height: 400,
                        }}>
                        <strong
                          style={{
                            float: "left",
                            marginBottom: 20,
                            fontSize:16,
                          }}>
                          Categories with Questions that have 25+ Respondents
                        </strong>
                        <Table
                          locale={customLocale}
                          dataSource={categoryQues}
                          columns={columns}
                          showHeader={false}
                          pagination={false}
                          scroll={{
                            y: 350,
                          }}
                        />
                      </div>
                    )}

                    {category.isVisible &&
                      (category.type === "table-max" ||
                        category.type === "table-max-two-criteria") && (
                        <div
                          style={{
                            height: 400,
                          }}>
                          <strong
                            style={{
                              float: "left",
                              marginBottom: 20,
                              fontSize:16,
                            }}>
                            Categories with Questions that have 25+ Respondents
                          </strong>
                          <Table
                            dataSource={categoryQuesMaxData}
                            columns={[...columnsMax]}
                            pagination={false}
                            scroll={{
                              y: 290,
                            }}
                          />
                        </div>
                      )}
                    {category.isVisible &&
                      (category.type === "table-funnel-max" ||
                        category.type === "table-funnel-max-two-criteria") && (
                        <div
                          style={{
                            height: 400,
                            // overflowY: "auto",
                            // padding: "1rem",
                          }}>
                          <div
                            style={{
                              textAlign: "left",
                              marginBottom: 20,
                              fontSize:16,
                            }}>
                            <strong>Patient Funnel</strong>
                          </div>
                          <div
                            id="container"
                            className="funnel-table-div"
                            style={{
                              position: "relative",
                              height: "100%",
                            }}>
                            {patientFunnelMultipleList.length > 3 ? (
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  position: "absolute",
                                  top: 0,
                                  zIndex: 10,
                                  width: "100%",
                                }}>
                                <LeftOutlined
                                  onClick={scrollLeft}
                                  className="custom-icon-left-outlined"
                                />
                                <RightOutlined
                                  onClick={scrollRight}
                                  className="custom-icon-right-outlined"
                                />
                              </div>
                            ) : null}
                            {empty ? (
                              <div
                                style={{
                                  display: "flex",
                                  overflowX: "auto",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  height: "90%",
                                }}>
                                {customLocale.emptyText}
                              </div>
                            ) : (
                              <Col
                                span={24}
                                ref={containerRef}
                                style={{
                                  display: "flex",
                                  overflowX: "auto",

                                  scrollBehavior: "smooth",
                                  padding: "0rem 1rem 1rem 1rem",
                                }}>
                                {patientFunnelMultipleList?.map(
                                  (item, index) => {
                                    return (
                                      <Col
                                        key={`${index}+1`}
                                        xs={{
                                          span: 24,
                                        }}
                                        sm={{
                                          span: 12,
                                        }}
                                        md={{
                                          span:
                                            patientFunnelMultipleList.length >=
                                            4
                                              ? 8
                                              : 24 /
                                                patientFunnelMultipleList.length,
                                        }}
                                        lg={{
                                          span:
                                            patientFunnelMultipleList.length >=
                                            4
                                              ? 8
                                              : 24 /
                                                patientFunnelMultipleList.length,
                                        }}
                                        xl={{
                                          span:
                                            patientFunnelMultipleList.length >=
                                            4
                                              ? 8
                                              : 24 /
                                                patientFunnelMultipleList.length,
                                        }}
                                        style={{
                                          padding: 0,
                                          userSelect: "none",
                                        }}>
                                        <Table
                                          style={{ height: "100%" }}
                                          dataSource={item.value}
                                          pagination={false}
                                          className="funnel-table"
                                          expandable={{
                                            expandIcon: ({
                                              expanded,
                                              onExpand,
                                              record,
                                            }) => {
                                              if (
                                                !record?.children ||
                                                record?.children?.length == 0
                                              )
                                                return <span></span>;

                                              return expanded ? (
                                                <UpOutlined
                                                  style={{
                                                    marginRight: "15px",
                                                  }}
                                                  onClick={(e) =>
                                                    onExpand(record, e)
                                                  }
                                                />
                                              ) : (
                                                <DownOutlined
                                                  style={{
                                                    marginRight: "15px",
                                                  }}
                                                  onClick={(e) =>
                                                    onExpand(record, e)
                                                  }
                                                />
                                              );
                                            },
                                          }}
                                          columns={item.headers.map((col) => ({
                                            ...col,
                                            ellipsis: col.ellipsis
                                              ? col.ellipsis
                                              : false,
                                            render: (text, record, index) => (
                                              <span style={{ height: 28 }}>
                                                {col.render
                                                  ? col.render(
                                                      text,
                                                      record,
                                                      index
                                                    )
                                                  : text}
                                              </span>
                                            ),
                                          }))}
                                          scroll={{ y: 290 }} // Set the height you desire for the fixed header
                                        />
                                      </Col>
                                    );
                                  }
                                )}
                              </Col>
                            )}
                          </div>
                        </div>
                      )}
                    {category.isVisible && category.radioButtons?.length && (
                      <Radio.Group
                        style={{
                          position: "absolute",
                          top: "10px",
                          left: "265px",
                          fontSize: "15px",
                          display: "flex",
                          gap: "0.5rem",
                          zIndex: "2",
                        }}
                        buttonStyle={"solid"}
                        onChange={(e) => onRadioChange(e, category)}
                        value={category.radioButtonSelectedValue}>
                        <span
                          style={{
                            fontSize: "14px",
                            fontWeight: "700",
                          }}>
                          View:
                        </span>
                        {category.radioButtons.map((radio) => (
                          <>
                            <Radio value={radio.id} disabled={radio.disabled}>
                              {radio.label}
                            </Radio>
                            {radio.info?.length && (
                              <img
                                src={InfoIcon}
                                title={radio.info}
                                alt={radio.info}
                                width={20}
                                style={{
                                  marginLeft: "-0.5rem",
                                }}
                              />
                            )}
                          </>
                        ))}
                      </Radio.Group>
                    )}
                    {category.isVisible &&
                      category.inputField &&
                      showAgeInterval && (
                        <div
                          style={{
                            position: "absolute",
                            top: "10px",
                            left: "65%",
                            fontSize: "15px",
                            display: "flex",
                            gap: "0.5rem",
                            zIndex: "2",
                            alignItems: "center",
                          }}>
                          Interval{" "}
                          <InputNumber
                            min={1}
                            max={120}
                            defaultValue={interval}
                            onPressEnter={handleKeyPress}
                            style={{ width: 60 }}
                          />
                        </div>
                      )}
                  </div>
                </Col>
              )
            );
          })}
        </Row>{" "}
      </Spin>
    </>
  );
};
export default ExploreOverview;
