import React, { useState, useEffect } from "react";
import "./Explore.scss";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  Col,
  Row,
  Button,
  Space,
  Divider,
  Tabs,
  Badge,
  Tag,
  Input,
  Spin,
} from "antd";
import FindingsDrawer from ".././findings-drawer/FindingsDrawer";
import aesEncryt from "../../shared/AesEncryt/AesEncryt";
import {
  DownloadOutlined,
  LoadingOutlined,
  CheckCircleTwoTone,
  PlusCircleOutlined,
} from "@ant-design/icons";
import FilterIcon from "../../assets/icons/filter-icon.svg";
import NeutralIcon from "../../assets/icons/Neutral-icon.svg";
import AgreeIcon from "../../assets/icons/Agree-blue-thumb.svg";
import Disagree from "../../assets/icons/Disagree-orange-thumb.svg";
import ExploreOverview from "../explore-overview/ExploreOverview";
import FindingReportSettingsModal from "../finding-report-setting-modal/FindingReportSettingsModal";
import ExploreResponseDetails from "../explore-response-details/ExploreResponseDetails";
import RespondentFilter from "../respondent-filter-drawer/RespondentFilter";
import {
  downloadExploreResponses,
  displayExploreFilterDataAPI,
  saveExploreFilterAPI,
} from "../../services/ExploreAPI";
import {
  setSearchTextExplore,
  setIsFindingsReportFullWidth,
  setExploreFilterApplyData,
  setNavigationTab,
  setFindingsReportAnalysisData,
  setFindingsReportAnalysisId,
  setFindingReportData,
  setIsFindingsReportIsOpen,
  setUserDetail,
} from "../../reducer/PEBCommonSlice";
const { Search } = Input;

function Explore() {
  const location = useLocation();
  const data = location.state;
  const dispatch = useDispatch();
  const isFullWidth = useSelector(
    (state) => state.PEBCommon?.findingsReport.isFullWidth
  );
  const editAccessFlag = useSelector(
    (state) => state.PEBCommon?.hasAccessToReport
  );
  const supportingData = useSelector(
    (state) => state.PEBCommon?.supportingData
  );
  const filterData = useSelector((state) => state.PEBCommon?.filterData);
  const recordData = useSelector((state) => state.PEBCommon?.recordData);
  const isOpen = useSelector((state) => state.PEBCommon?.findingsReport.isOpen);
  const userInfo = useSelector((state) => state.PEBCommon?.userDetail); // get User details
  const userId = userInfo.user_id;
  const tabDefaultValue = data?.tabValue ? data.tabValue : "1";
  const [respondentFilterFlag, setRespondentFilterFlag] = useState(false);
  const [tags, setTags] = useState([]);
  let [extraKeys, setExtraKeys] = useState();
  const idmUrl = process.env.REACT_APP_IDM_URL;
  const [isOpenModal, setIsOpen] = useState(false);
  // const surveyButtonClicked = () => {};

  let [filterTagCount, setFilterTagCount] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const filterAppliedData = useSelector(
    (state) => state.PEBCommon?.exploreFilterApplyData
  );
  const searchString = useSelector(
    (state) => state.PEBCommon?.searchTextExplore
  );
  const analysisData = useSelector(
    (state) => state.PEBCommon?.findingsReport.analysisData
  );
  const [loading, setLoading] = useState(false);
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );
  if (!isOpen) {
    localStorage.removeItem("analysisId");
    localStorage.removeItem("suppCardOrder");
  }
  const respondantFilterOpen = (state) => {
    setRespondentFilterFlag(state);

    if (!state) displayFilterDataPayload();
  };
  const options = [];
  for (let i = 10; i < 36; i++) {
    options.push({
      value: i.toString(36) + i,
      label: i.toString(36) + i,
    });
  }
  useEffect(() => {
    setRespondentFilterFlag(false);
  }, [editAccessFlag]);

  const items = [
    {
      key: "1",
      label: `Data Coverage`,
      children: (
        <ExploreOverview
          updateFilterCountBadge={(event) => filterCountUpdateHandler(event)}
          updateFilterRespondentsTag={(event) =>
            filterRespondentsTagHandler(event)
          }
        />
      ),
    },
    {
      key: "2",
      label: `View Insights`,
      children: <ExploreResponseDetails />,
    },
  ];

  //lehgend Popover Content for Responses Tab
  const content = (
    <div>
      <span style={{ paddingRight: 15 }}>
        {" "}
        <img
          alt={"Agree Icon"}
          src={AgreeIcon}
          style={{ verticalAlign: "text-bottom", paddingRight: 5 }}
        />
        Agree
      </span>
      <span style={{ paddingRight: 15 }}>
        <img
          alt={"Disagree"}
          src={Disagree}
          style={{ verticalAlign: "text-bottom", paddingRight: 5 }}
        />
        Disagree
      </span>
      <span style={{ paddingRight: 15 }}>
        <img
          alt={"Neutral Icon"}
          src={NeutralIcon}
          style={{ verticalAlign: "text-bottom", paddingRight: 5 }}
        />
        Neutral
      </span>
    </div>
  );

  const onChange = (key) => {
    // if (key === "2") {
    //   setExtraKeys(
    //     <>
    //       <Popover placement="bottomRight" content={content}>
    //         <div>
    //           <InfoCircleOutlined /> <span>Legend</span>
    //         </div>
    //       </Popover>
    //     </>
    //   );
    // } else {
    //   setExtraKeys();
    // }
  };

  const handleClose = (removedTagObj) => {
    let removedTag = removedTagObj.comparator_name;
    setLoading(true);
    const newTags = tags.filter((tag) => tag !== removedTag);

    deleteFilterTagData(removedTag);
    setTags(newTags);
    // setLoading(false);
  };

  const deleteFilterTagData = (removedTag) => {
    let updatedFilterData =
      filterAppliedData.length === 1
        ? [
            {
              therapeutic_area: [],
              indication: [],
              age: [],
              sub_indication: [],
              ethnicity: [],
              geography: [],
              origin_country: [],
              race: [],
              gender: [],
              comparator_name: "Criteria 1",
              primary_flag: true,
            },
          ]
        : filterAppliedData.filter(
            (data) => data.comparator_name !== removedTag
          );
    updatedFilterData = updatedFilterData.map((filter) => {
      return {
        therapeutic_area: filter.therapeutic_area || [],
        primary_indication: filter.primary_indication || [],
        other_indication:filter.other_indication || [],
        age: filter.age || [],
        mapped_data: filter.mapped_data || [],
        sub_indication: filter.sub_indication || [],
        ethnicity: filter.ethnicity || [],
        geography: filter.geography || [],
        origin_country: filter.origin_country || [],
        race: filter.race || [],
        gender: filter.gender || [],
        comparator_name: filter.comparator_name,
        primary_flag: filter.primary_flag,
        other_mapped_data: filter.other_mapped_data || [],
        year_range : filter.year_range || [],
        stage : filter.stage || [],
      };
    });
    let payload = {
      user_id: userId,
      screen_name: "data_coverage",
      comparator_flag: true,
      delete_comparator_names: [removedTag],
      filters_data: updatedFilterData,
      display_chart_nm: [],
      analysis_id: localStorage.getItem("analysisId") || "",
    };
    saveFilterData(payload);
  };

  const saveFilterData = async (payload) => {
    let response = await saveExploreFilterAPI(payload);

    displayFilterDataPayload();
  };

  //to search the Questions and categories
  const onSearch = (e) => {
    setSearchTerm(e.target.value);
  };
  const handleSearch = () => {
    if (searchTerm.length > 2 || searchTerm.length === 0) {
      dispatch(setSearchTextExplore(searchTerm));
    }
  };

  function getCookie(name) {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      // Check if the cookie starts with the specified name
      if (cookie.startsWith(name + "=")) {
        // Return the value of the cookie
        return cookie.substring(name.length + 1); // Remove the name and '=' from the beginning
      }
    }
    // Return null if the cookie is not found
    return null;
  }
  function redirectToAnalysisLibrary(id) {
    const redirectToAnalysisLibraryUrl = process.env.PUBLIC_URL;
    window.location.href = `${redirectToAnalysisLibraryUrl}/analysis-library?id=${id}`;
  }
  function deleteCookie(name) {
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  }
  useEffect(() => {
    displayFilterDataPayload();
    dispatch(setIsFindingsReportFullWidth(false));
    dispatch(setNavigationTab("/explore"));
    const idFromCookie = getCookie("id");
    // If 'id' exists in the cookie, redirect to the analysis library
    if (idFromCookie) {
      deleteCookie("id");
      redirectToAnalysisLibrary(idFromCookie);
    }
    // if (editAccessFlag === false) {
    //   dispatch(setIsFindingsReportIsOpen(false));
    //   dispatch(setIsFindingsReportFullWidth(false));
    //   dispatch(setFindingReportData({ insight_list: [], supporting_data: [] }));
    //   dispatch(setFindingsReportAnalysisId(null));
    //   dispatch(setFindingsReportAnalysisData({}));
    // }
  }, []);

  useEffect(() => {
    const debounceId = setTimeout(() => {
      if (searchTerm.length > 2 || searchTerm.length === 0) {
        dispatch(setSearchTextExplore(searchTerm));
      }
    }, 1000);

    // Clear the timeout on unmount or when the searchTerm changes
    return () => clearTimeout(debounceId);
  }, [searchTerm]);

  //to Download Explore Screen Responses
  const downloadClicked = async () => {
    sessionStorage.setItem("Download", "yes");
    const payload = {
      user_id: userId,
      screen_name: "data_coverage",
      analysis_id: localStorage.getItem("analysisId") || "",
    };

    let response = await downloadExploreResponses(payload);
    window.open(aesEncryt(response?.export_url), "_parent");
    sessionStorage.setItem("Download", "no");
  };
  const modalOpen = async () => {
    setIsOpen(true);
  };

  const filterCountUpdateHandler = (count) => {
    setFilterTagCount(count);
  };

  const filterRespondentsTagHandler = (tags) => {
    setTags(tags);
  };

  const displayFilterDataPayload = () => {
    let payload = {
      user_id: userId,
      screen_name: "data_coverage",
      analysis_id: localStorage.getItem("analysisId") || "",
      analysis_refresh_date: analysisData?.last_updated || "",
      ques_max_updated_ts: analysisData?.quesMaxUpdatedTs || "",
    };
    displayFilterData(payload);
  };

  let filterCountBadge = 0;
  let filterRespondentsTag = [];
  //API call to Display Filters Data
  const displayFilterData = async (payload) => {
    setLoading(true);
    let response = await displayExploreFilterDataAPI(payload);

    filterCountBadge = 0;
    filterRespondentsTag = [];
    // if (!filterAppliedData)

    // TO PREVENT UPDATING OF FILTER DATA FROM API

    if (
      JSON.stringify(response?.result?.filters_data) !==
      JSON.stringify(filterAppliedData)
    ) {
      // Dispatch the action only if there's a change
      dispatch(setExploreFilterApplyData(response?.result?.filters_data));
    }
    if (Object.keys(response?.result).length) {
      response?.result?.filters_data.forEach((comp) => {
        Object.keys(comp).forEach((column) => {
          let columnsToExclude = [
            "comparator_name",
            "primary_flag",
            "indication",
            "sub_indication",
            "no_of_respondents",
            "comparator_order",
          ];
          if (!columnsToExclude.includes(column) && comp[column]?.length) {
            filterCountBadge++;
          }
        });
        if (comp["indication"]?.length || comp["sub_indication"]?.length)
          filterCountBadge++;
        else if (
          response?.result?.filters_data.length &&
          comp["comparator_name"]
        ) {
        }
        filterRespondentsTag.push({
          comparator_name: comp["comparator_name"],
          no_of_respondents: comp["no_of_respondents"],
        });
      });
    }

    filterCountUpdateHandler(filterCountBadge);
    filterRespondentsTagHandler(filterRespondentsTag);
    setLoading(false);
  };

  useEffect(() => {
    const isAnyvalEmpty = Object.values(analysisData).some(
      (value) => value == ""
    );
    if (!isAnyvalEmpty) {
      displayFilterDataPayload();
    }
  }, [analysisData]);

  return (
    <>
      {isOpenModal && (
        <FindingReportSettingsModal
          isOpen={isOpenModal}
          setIsOpen={setIsOpen}
          supporting_data={supportingData}
          filterData={filterData}
          recordData={recordData}
          type={"category_responses"}
          addFlag={false}
          createAddFlag={true}
        />
      )}
      <Spin indicator={antIcon} spinning={loading}>
        <Row wrap={false}>
          {
            <Col
              flex="auto"
              style={{
                marginLeft: 8,
                marginRight: 8,
                padding: "24px 14px",
                display: isFullWidth ? "none" : "block",
              }}
            >
              <Row style={{ marginTop: 10, marginBottom: 20 }}>
                <Col span={12}>
                  <span className="sub-header">
                    {filterTagCount < 1
                      ? `Explore Data from  ${
                          tags[0]?.no_of_respondents
                            ? tags[0]?.no_of_respondents.toLocaleString()
                            : ""
                        } Respondents`
                      : "Explore"}
                  </span>
                </Col>
                <Col span={12} style={{ float: "right" }}>
                  <Space style={{ float: "right" }}>
                    {/* <Button
                className="configure-btn"
                onClick={() => surveyButtonClicked()}
              >
                <img
                  alt={"Survey Insights Icon"}
                  src={SurveyInsightsIcon}
                  style={{ verticalAlign: "text-bottom", paddingRight: 5 }}
                />
                Survey for More Insights
              </Button> */}
                    <Space
                      direction="vertical"
                      style={{ display: "flex", alignItems: "flex-end" }}
                      Size={7}
                    >
                      <Button
                        style={{ width: 40 }}
                        className="configure-btn"
                        icon={<DownloadOutlined />}
                        onClick={() => downloadClicked()}
                      ></Button>
                    </Space>
                  </Space>
                </Col>
              </Row>
              <Row>
                <Col style={{ marginRight: "10px", marginTop: "10px" }}>
                  <Space direction="vertical" style={{ float: "left" }}>
                    <Search
                      placeholder="Search Questions by Keyword"
                      onChange={onSearch}
                      onSearch={handleSearch} // This will be triggered when Enter is pressed
                      value={searchTerm} // Controlled input value
                      size={"large"}
                      enterButton={false}
                      style={{ width: 350, borderRadius: "0px" }}
                    />
                  </Space>
                </Col>
                <Space>
                  <Divider type="vertical" className="divider-vertical" />
                  <Badge
                    size="default"
                    color="#027AFF"
                    count={
                      filterTagCount != 0 ? (
                        <CheckCircleTwoTone style={{ fontSize: "18px" }} />
                      ) : (
                        0
                      )
                    }
                  >
                    <Button
                      className="configure-btn"
                      onClick={() => respondantFilterOpen(true)}
                    >
                      <img
                        alt={"Filter Icon"}
                        src={FilterIcon}
                        style={{
                          verticalAlign: "text-bottom",
                          paddingRight: 5,
                        }}
                      />
                      Define Target Population{" "}
                    </Button>
                  </Badge>
                </Space>
                <Space style={{ marginLeft: "auto" }}>
                  {" "}
                  <Button
                    style={{ height: 40 }}
                    type={
                      !localStorage.getItem("analysisId")
                        ? "primary"
                        : undefined
                    }
                    icon={<PlusCircleOutlined />}
                    className={
                      !localStorage.getItem("analysisId")
                        ? "analysis-btn"
                        : undefined
                    }
                    onClick={() => modalOpen()}
                  >
                    Create Report
                  </Button>
                </Space>
              </Row>
              {filterTagCount >= 1 && (
                <Row style={{ marginTop: 20, alignItems: "center" }}>
                  <span className="tags-label">Target Population Count: </span>
                  <Space size={[0, "small"]} wrap style={{ marginLeft: 20 }}>
                    {tags.map((tag) => {
                      return (
                        <Tag
                          key={tag}
                          bordered={true}
                          closable={editAccessFlag}
                          color={tag.no_of_respondents === 0 ? "red" : "blue"}
                          style={{
                            display: "flex",
                            height: 32,
                            alignItems: "center",
                          }}
                          title={`${tag.no_of_respondents}: ${tag.comparator_name}`}
                          onClose={
                            !editAccessFlag ? null : () => handleClose(tag)
                          }
                        >
                          <div
                            style={{
                              maxWidth: 250,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            <strong>{tag.no_of_respondents}</strong>
                          </div>{" "}
                          : {tag.comparator_name}
                        </Tag>
                      );
                    })}
                  </Space>
                </Row>
              )}
              <div style={{ marginTop: 20 }}>
                {/* {filterAppliedData?.length && ( */}
                <Tabs
                  defaultActiveKey={tabDefaultValue}
                  destroyInactiveTabPane={true}
                  items={items}
                  onChange={onChange}
                  tabBarExtraContent={extraKeys}
                />
                {/* )} */}
              </div>
            </Col>
          }

          {respondentFilterFlag ? (
            <div>
              <RespondentFilter
                visible={true}
                onClose={() => respondantFilterOpen(false)}
              />
            </div>
          ) : (
            ""
          )}
          {isOpen && (
            <Col
              style={
                isFullWidth
                  ? { minWidth: "100%", maxWidth: "100%", zIndex: 998 }
                  : { minWidth: "26vw", maxWidth: "26vw", zIndex: 1001 }
              }
            >
              <FindingsDrawer
                isShrinkable={isFullWidth ? true : false}
                isClosable={false}
                isExpandable={isFullWidth ? false : true}
                fullWidth={isFullWidth}
              />
            </Col>
          )}
        </Row>
        {/* </div> */}
      </Spin>
    </>
  );
}

export default Explore;
